<template>
  <div
    class="login"
    :style="{
      backgroundImage: 'url('+bg+')',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    }"
  >
    <el-row>
      <el-col
        class="registerBox"
        :xs="{ offset: '2', span: '20' }"
        :sm="{ offset: '4', span: '16' }"
        :md="{ offset: '14', span: '8' }"
        :lg="{ offset: '14', span: '8' }"
        :xl="{ offset: '12', span: '6' }"
      >
        <div class="title">登录</div>
        <!-- 账号密码登录 -->
        <el-form
          v-if="loginType === 1"
          :model="ruleForm"
          ref="ruleForm"
          label-width="46px"
          class="demo-ruleForm"
        >
          <el-form-item label class>
            <el-row>
              <el-col
                :span="12"
                :class="loginType === 1 ? 'actived pointer' : 'pointer'"
                @click.native="changeLoginType(1)"
                >账户密码登录</el-col
              >
              <el-col
                :span="12"
                :class="loginType === 2 ? 'actived pointer' : 'pointer'"
                @click.native="changeLoginType(2)"
                >手机号登录</el-col
              >
            </el-row>
          </el-form-item>
          <el-form-item label prop="name">
            <el-input
              type="text"
              v-model="ruleForm.name"
              placeholder="请输入账号、邮箱、手机号"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="checkname"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="checkname === false"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>

          <el-form-item label prop="password" class="noMargrin">
            <el-input
              v-model="ruleForm.password"
              type="password"
              @blur="checkPassword"
              @focus="checkPwd = null"
              placeholder="密码"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="checkPwd"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="checkPwd === false"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>
          <p class="pwdtips" v-if="checkPwd">
            密码应为8-16个字符，至少包含数字和字母
          </p>

          <el-form-item
            prop="yzm"
            style="text-align: left; margin-top: 1.25rem"
            class="noMargrin"
            v-if="error_times > 2"
          >
            <el-input
              v-model="ruleForm.yzm"
              placeholder="验证码"
              maxlength="4"
              style="float: left; width: 50%; margin-right: 5%"
            ></el-input>
            <el-image
              :src="yzmurl"
              @click="changeyzmurl"
              style="width: 120px; height: 38px; border: 1px solid #c0c4cc"
            ></el-image>
          </el-form-item>

          <el-form-item label prop="desc" style="text-align: left">
            <el-row>
              <el-col :span="12">
                <el-checkbox
                  v-model="ruleForm.isOk"
                  @change="autoLogin"
                  >自动登录</el-checkbox
                >
              </el-col>
              <el-col
                :span="6"
                :offset="6"
                class="pointer"
                @click.native="toChangePwd"
                style="color: blue"
                >忘记密码</el-col
              >
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%; background: blue"
              @click="login"
              >登录</el-button
            >
          </el-form-item>
          <el-form-item class="noMargrin">
            <el-row>
              <el-col :span="16" class="iconBox">
                <span>其他登录方式</span>
                <!-- <span class="other"> -->
                <img
                  :src="require('@/assets/images/icons/qqlink.png')"
                  alt
                  @click="qingqiuqqlogin"
                />
                <img :src="require('@/assets/images/icons/wxlink.png')" alt />
                <img
                  :src="require('@/assets/images/icons/weibolink.png')"
                  alt
                  @click="qingqiuwblogin"
                />
                <!-- </span> -->
              </el-col>
              <el-col :span="7" class>
                <span class="pointer" style="color: blue" @click="toRigster"
                  >注册账户</span
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item class="noMargrin">
            <el-row>
              <el-col
                :span="6"
                :offset="6"
                class="pointer"
                @click.native="toNewHelp"
                >新手帮助</el-col
              >
              <el-col :span="6" class="pointer" @click.native="toService"
                >隐私协议</el-col
              >
            </el-row>
          </el-form-item>
        </el-form>
        <!-- 手机号登录 -->
        <el-form
          v-if="loginType === 2"
          :model="ruleForm"
          ref="ruleFormPhone"
          :rules = 'rules'
          label-width="46px"
          class="demo-ruleForm"
        >
          <el-form-item label>
            <el-row>
              <el-col
                :span="12"
                :class="loginType === 1 ? 'actived pointer' : 'pointer'"
                @click.native="changeLoginType(1)"
                >账户密码登录</el-col
              >
              <el-col
                :span="12"
                :class="loginType === 2 ? 'actived pointer' : 'pointer'"
                @click.native="changeLoginType(2)"
                >手机号登录</el-col
              >
            </el-row>
          </el-form-item>
          <el-form-item label prop="phone">
            <el-input
              type="text"
              v-model="ruleForm.phone"
              @focus="inputIcon = ''"
              placeholder="请输入手机号"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="inputIcon == 'el-icon-circle-close'"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="inputIcon == 'el-icon-circle-check'"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>

          <el-form-item label prop="code">
            <el-row>
              <el-col :span="15">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="输入验证码"
                >
                  <i
                    slot="suffix"
                    style="color: red"
                    v-if="ruleForm.code.length != 6 && ruleForm.code.length > 0"
                    class="el-icon-circle-close"
                  ></i>
                  <i
                    slot="suffix"
                    style="color: green"
                    v-if="ruleForm.code.length == 6"
                    class="el-icon-circle-check"
                  ></i>
                </el-input>
              </el-col>
              <el-col :span="8" :offset="1">
                <el-button
                  :disabled="disableds"
                  @click.native="getMsg"
                  style="padding: 12px 6px; width: 100%"
                  >{{
                    disableds ? secendNo + "S后重新获取" : "发送验证码"
                  }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%; background: blue"
              @click="loginByCode"
              >登录</el-button
            >
          </el-form-item>
          <el-form-item class="noMargrin">
            <el-row>
              <el-col :span="16" class="iconBox">
                <span>其他登录方式</span>
                <!-- <span class="other"> -->
                <img
                  :src="require('@/assets/images/icons/qqlink.png')"
                  alt
                  @click="qingqiuqqlogin"
                />
                <img :src="require('@/assets/images/icons/wxlink.png')" alt />
                <img
                  :src="require('@/assets/images/icons/weibolink.png')"
                  alt
                />
                <!-- </span> -->
              </el-col>
              <el-col :span="7" class>
                <span class="pointer" style="color: blue" @click="toRigster"
                  >注册账户</span
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item class="noMargrin">
            <el-row>
              <el-col
                :span="6"
                :offset="6"
                class="pointer"
                @click.native="toNewHelp"
                >新手帮助</el-col
              >
              <el-col :span="6" class="pointer" @click.native="toService"
                >隐私协议</el-col
              >
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {apiPhoneLogin,apiGetMsgCode,apiAccountLogin,aoiGetUserinfo} from '@/api/login'
export default {
  name: "login",
  data() {
    return {
      bg: require("@/assets/images/logbgs.png"),
      loginType: 1,
      disableds: false,
      secendNo: 60,
      timer: null,
      ruleForm: {
        yzm: "",
        name: "",
        password: "",
        inPhone: "",
        code: "",
        phone: "",
        isOk: false,
      },
      rules: {
         phone: [
            {
                required: true,
                message: "请输入手机号",
                trigger: "blur"
            },
            {
                pattern: /^1[3456789]\d{9}$/,
                message: "请输入正确的手机号",
                trigger: "blur"
            },
          
        ]
      },
      name: "",
      password: "",
      checkPwd: null,
      checkname: null,
      inPhone: "",
      code: "",
      isOk: false,
      inputIcon: "",
      yzmurl: "/wealth/getVerify?" + Math.random(),
      error_times: 0,
      token: ''
    };
  },
  methods: {
    changeyzmurl() {
      this.yzmurl = "/wealth/getVerify?" + Math.random();
    },
    qingqiuqqlogin() {
  
      const data = {};
      this.axios
        .post("/wealth/qqRequestLogin", data)
        .then(() => {
          location.href =
            "https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101846023&redirect_uri=https%3a%2f%2fwww.lcpz.com%2fqq&state=test";
        });
    },
    qingqiuwblogin() {
      const data = {};
      this.axios
        .post("/wealth/wbRequestLogin",data)
        .then(() => {
          location.href =
            "https://api.weibo.com/oauth2/authorize?client_id=98634165&response_type=code&redirect_uri=https%3a%2f%2flcpz.com%2fwb";
        });
    },
    //自动登录
    autoLogin(val) {
      window.localStorage.setItem("lcAutoLogin", val ? "1" : "0");
    },
    //隐私协议
    toService() {
      this.$router.push({ name: "service" });
      this.$store.commit("setInfo", ["menuIndex", 3]);
      this.$store.commit("setInfo", ["aboutIndex", "service"]);
    },
    //新手帮助
    toNewHelp() {
      this.$router.push({ name: "question" });
      this.$store.commit("setInfo", ["menuIndex", 3]);
      this.$store.commit("setInfo", ["aboutIndex", "question1"]);
    },
    //校验密码
    checkPassword() {
      const str = /^[a-z0-9_-]{8,16}$/;
      this.checkPwd = !str.test(this.ruleForm.password);
    },
    /*,
     *@date: 2019-11-06 21:38:55
     *@description: 账号密码登录
     */
    login() {
      const that = this;
      const data = {
        password: this.ruleForm.password,
        account: this.ruleForm.name,
        veritycode: this.ruleForm.yzm,
        errortimes: this.error_times,
        spread_spid: '0'
      };
      apiAccountLogin(data).then((res) => {
        if (res.code === 200) {
          console.log(res);
          this.$message({
            message: '登录成功',
            type: "success",
            center: true,
          });
          this.token = res.data.token
          window.localStorage.setItem("access_token", res.data.token);
          window.localStorage.setItem("userInfo",JSON.stringify(res.data) );
          this.$store.commit('SET_INFO',['token',res.data.token])
          this.getUserInfo();
          that.$router.push("/index");
        } else {
          that.$message.warning(res.message);
        }
      });
    },
    /*
     *@date: 2019-11-06 20:17:52
     *@description: 获取验证码
     */
    getMsg() {
        if(!this.ruleForm.phone){
            this.$message.warning('请输入手机号')
            return false
        }
      const data = { phone: this.ruleForm.phone };
      apiGetMsgCode(data)
        .then(() => {
          this.$message({
            message: '发送成功',
            type: "success",
            center: true,
            offset: 300,
            duration: 2000,
          });
          if (!this.timer) {
            this.secendNo = 60;
            this.disableds = true;
            this.timer = setInterval(() => {
              if (this.secendNo > 0 && this.secendNo <= 60) {
                this.secendNo--;
              } else {
                this.disableds = false;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        });
    },
    /*
     *@date: 2019-11-10 14:04:43
     *@description: 手机号 验证码登录
     */
    loginByCode() {
        this.$refs.ruleFormPhone.validate(valid => {
            if(valid){
                const that = this;
                const data = { account: this.ruleForm.phone, captcha: this.ruleForm.code };
                apiPhoneLogin(data).then((res) => {
                    if (res.code === 200) {
                        this.$message({
                          message: '登录成功',
                          type: "success",
                          center: true,
                        });
                        this.token = res.data.token
                        localStorage.setItem("access_token", res.data.token);
                        localStorage.setItem("userInfo",JSON.stringify(res.data) );
                        this.$store.commit('SET_INFO',['token',res.data.token])
                        this.getUserInfo();
                        that.$router.push("/index");
                      } else {
                        that.$message.warning(res.message);
                      }
                });
            }
        })
      
    },
    //获取用户信息
    getUserInfo() {
    
      aoiGetUserinfo().then(res=>{
        if(res.code == 200){
          console.log(res);
          localStorage.setItem('userInfo',JSON.stringify(res.data))
          this.$store.commit('SET_INFO',['userInfo',res.data])
        }
      })
    },
    /*
     *@date: 2019-11-10 16:25:59
     *@description: modify password
     */
    toChangePwd() {
      this.$router.push("/repassword");
    },
    toRigster() {
      this.$router.push("/register");
    },
    //获取未读信息
    /*
     *@description: 改变登录方式
     *@date: 2019-11-03 14:53:38
     *@version: V1.0.5
     */
    changeLoginType(index) {
      this.loginType = index;
    },
  },

  mounted() {
    this.changeyzmurl();
    if (window.localStorage.getItem("lcAutoLogin") == "1") {
      this.login();
    }
  },
};
</script>
<style lang="scss" scoped>
.login {
  border-top: 1px dashed #999;
  height: 750px;
  // background: url('@/assets/images/loginImg.png') no-repeat 100%;
}
.noMargrin {
  margin-bottom: 0;
}
.pointer {
  cursor: pointer;
}
.pwdtips {
  text-align: left;
  text-indent: 46px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: orange;
}
.iconBox {
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    width: 22px;
    height: 22px;
    display: inline-block;
  }
}
.registerBox {
  //   border: 1px solid #797979;
  margin-top: 70px;
  margin-bottom: 85px;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  .title {
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    margin: 17px 0 0;
  }
  .demo-ruleForm {
    padding-right: 74px;
  }
  .btnBox {
    border: 1px solid #dcdfe6;
    line-height: 38px;
  }
}
.actived {
  color: blue;
}
</style>